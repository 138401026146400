.body-inicio {
  display: block;
  min-height: 90vh;
  width: 100%;
  background-color: #000000;
}

.container-flex-inicio {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.container-inicio-1 {
  width: 100%;
  height: 425px;
  margin-bottom: 75px;
  position: relative;
}

.imagen-de-fond-1 {
  background-image: url("https://surdelosandes.com/wp-content/uploads/2023/09/paisaje-surdelosandes-2.jpg-e1694911376211.png");
  background-size: cover;
  background-position: bottom;
}

.imagen-de-fond-2 {
  background-image: url("https://surdelosandes.com/wp-content/uploads/2023/04/BROCHURE4.jpg");
  background-size: cover;
  background-position: bottom;
}

.container-inicio-1 img {
  position: absolute;
  top: -25%;
  left: 50%;
  transform: translateX(-50%);
  height: 600px;
}

.img-slider-2 img {
  top: 0%;
  height: 500px;
}

.img-frutas img {
  top: 10%;
  left: 15%;
  height: 300px;
  width: 300px;
}

.container-inicio-1 p {
  position: absolute;
  color: #ffffff;
  font-size: 32px;
  font-weight: bold;
  width: 30%;
  left: 60%;
  top: 30%;
}

.button-slider {
  position: absolute;
  left: 60%;
  top: 80%;
  font-size: 13px;
  width: 220px;
  height: 35px;
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
}

.button-slider:hover {
  background-color: #000000;
  top: 81%;
}

.button-new-slider {
  position: absolute;
  left: 60%;
  top: 80%;
  font-size: 13px;
  width: 220px;
  height: 35px;
  background-color: transparent;
  border: 1px solid #000000;
  color: #000000;
  font-weight: bold;
  cursor: pointer;
}

.button-new-slider:hover {
  background-color: #000000;
  color: #ffffff;
  border: 1px solid gray;
  top: 81%;
}

.container-buttons-slider {
  width: 5px;
  display: grid;
  position: relative;
  left: 97%;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
}

.button-select-slider {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid black;
  background: #000000;
  cursor: pointer;
}

.button-select-slider-2 {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid black;
  background: transparent;
  cursor: pointer;
}

/*------------------------------------- container-inicio-2 ------------------------------------------- */

.container-inicio-2 {
  width: 100%;
  display: block;
  background: #1a1a1a;
  padding: 75px 0px;
  color: #ffffff;
}

.container-inicio-2 .container-flex-inicio h3 {
  font-size: 16px;
  margin-bottom: 30px;
}

.container-inicio-2 .container-flex-inicio h1 {
  font-size: 32px;
  width: 60%;
  font-weight: bold;
  margin-bottom: 60px;
}

.container-inicio-2-left {
  width: 45%;
  margin: 10px;
}

.container-inicio-2-left img {
  width: 350px;
}

.container-inicio-2-right {
  width: 45%;
  text-align: left;
  margin: 10px;
}

.container-inicio-2-right p {
  font-size: 15px;
}

.button-1-container-inicio2 {
  width: 190px;
  height: 55px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
  background-color: #ae132e;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  margin: 10px;
  cursor: pointer;
  transition: all 0.5s;
}

.button-1-container-inicio2:hover {
  background-color: #ffffff;
  color: #ae132e;
}

.button-2-container-inicio2 {
  width: 190px;
  height: 55px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  margin: 10px;
  cursor: pointer;
  transition: all 0.5s;
}

.button-2-container-inicio2:hover {
  background-color: #ffffff;
  color: #ae132e;
  border: 1px solid #ae132e;
}

/*------------------------------------- container-inicio-3 ------------------------------------------- */

.container-inicio-3 {
  width: 100%;
  display: block;
  padding: 30px 0px;
  background-color: #ffffff;
  -webkit-clip-path: polygon(
    42% 1%,
    74% 5%,
    100% 1%,
    100% 100%,
    78% 94%,
    41% 98%,
    23% 95%,
    0 100%,
    0 0,
    18% 5%
  );
  clip-path: polygon(
    42% 1%,
    74% 5%,
    100% 1%,
    100% 100%,
    78% 94%,
    41% 98%,
    23% 95%,
    0 100%,
    0 0,
    18% 5%
  );
  color: #000000;
}

.container-inicio-3 .container-flex-inicio h3 {
  font-size: 16px;
  margin-bottom: 30px;
}

.container-inicio-3 .container-flex-inicio h1 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 75px;
}

.container-inicio-3-slider {
  width: 100%;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.container-inicio-3-slider svg {
  position: absolute;
  right: 25px;
  cursor: pointer;
}

.card-container-inicio-3 {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 50px;
  cursor: pointer;
  color: #000000;
}

.container-card-left {
  width: 80px;
  height: 220px;
  position: relative;
}

.container-card-left img {
  height: 350px;
  position: absolute;
  left: -220px;
  top: -80px;
}

.img-arrepentido img {
  top: -92px;
}

.container-card-right {
  width: 170px;
  height: 220px;
  display: block;
}

.tittle-card {
  font-size: 18px;
  font-weight: bold;
}

.sub-tittle-card {
  font-size: 15px;
  font-weight: 100px;
  opacity: 0.6;
}

/*----------------------------- container-inicio-4 -------------------------------------------- */

.container-inicio-4 {
  display: block;
  padding: 75px 0px;
  color: #ffffff;
  background-image: url("../../imagenes/imagen-11.jpg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}

.container-inicio-4 h1 {
  font-size: 45px;
  margin-left: 50px;
  font-weight: bold;
  width: 700px;
}

.container-inicio-4 p {
  font-size: 20px;
  margin-left: 50px;
  width: 700px;
}

.margin-left-container-4 {
  margin-left: 50px;
}

/*-------------------------------------container-inicio-5-------------------------------------------*/

.container-inicio-5 {
  padding: 125px 0px;
  color: #000000;
  display: block;
  background-color: #f5f5dc;
  -webkit-clip-path: polygon(
    50% 0%,
    80% 3%,
    100% 0,
    100% 100%,
    0 100%,
    0 0,
    27% 5%
  );
  clip-path: polygon(50% 0%, 80% 3%, 100% 0, 100% 100%, 0 100%, 0 0, 27% 5%);
}

.container-inicio-5 h3 {
  font-size: 16px;
  margin-bottom: 50px;
}

.container-inicio-5 h1 {
  font-size: 32px;
  margin-bottom: 75px;
  width: 60%;
  font-weight: bold;
}

.container-cards-inicio-5 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.card-inicio-5 {
  display: block;
  width: 25%;
  text-align: center;
  font-size: 16px;
}

.card-inicio-5 img {
  width: 132px;
  height: 132px;
  margin-bottom: 25px;
}

.card-inicio-5 h5 {
  font-weight: bold;
}

.card-inicio-5 button {
  background-color: transparent;
  border: none;
  color: #cc3366;
  cursor: pointer;
}

.card-inicio-5 button:hover {
  font-weight: bold;
}

/*------------------------------------- media screen ------------------------------------------- */

@media screen and (max-width: 900px) {
  .img-slider-2 img {
    top: 0%;
    left: 80px;
    height: 500px;
  }

  .img-frutas {
    display: none;
  }

  .container-inicio-1 p {
    width: 70%;
    left: 30%;
    top: 30%;
  }

  .button-slider {
    left: 30%;
    top: 70%;
  }

  .button-slider:hover {
    background-color: #000000;
    top: 71%;
  }

  .container-inicio-2-left {
    width: 50%;
  }

  .container-inicio-2-right {
    width: 50%;
  }

  .container-inicio-5 h1 {
    width: 90%;
  }

  .container-cards-inicio-5 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  .card-inicio-5 {
    width: 100%;
    padding: 0px 15px;
  }
}

@media screen and (max-width: 850px) {
  .container-inicio-3-slider {
    display: grid;
  }

  .container-inicio-4 h1 {
    width: 85%;
  }

  .container-inicio-4 p {
    width: 85%;
  }
}

@media screen and (max-width: 700px) {
  .img-slider-2 img {
    left: 85%;
  }

  .container-inicio-1 p {
    width: 70%;
    left: 5%;
    top: 30%;
    font-size: 28px;
  }

  .button-slider {
    left: 5%;
    top: 70%;
  }

  .contaner-block-inicio {
    display: block;
    padding: 0px 15px;
  }

  .container-inicio-2-left {
    width: 100%;
    margin: 0;
    margin-bottom: 50px;
  }

  .container-inicio-2-left img {
    width: 300px;
  }

  .container-inicio-2-right {
    width: 100%;
    margin: 0;
  }

  .container-inicio-3 {
    padding: 75px 0px;
  }
}

@media screen and (max-width: 560px) {
  .img-slider-2 img {
    left: 75%;
  }

  .container-buttons-slider {
    left: 95%;
  }

  .container-inicio-1 p {
    width: 62%;
    left: 2%;
    top: 30%;
    font-size: 24px;
  }

  .button-slider {
    left: 2%;
    top: 70%;
  }

  .container-inicio-2 .container-flex-inicio h1 {
    font-size: 32px;
    width: 90%;
    font-weight: bold;
    margin-bottom: 60px;
  }

  .container-inicio-4 h1 {
    margin-left: 10px;
    width: 95%;
  }

  .container-inicio-4 p {
    margin-left: 10px;
    width: 95%;
  }

  .margin-left-container-4 {
    margin-left: 10px;
  }

  .container-inicio-5 h1 {
    font-size: 26px;
    width: 95%;
  }

  .container-cards-inicio-5 {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .card-inicio-5 {
    width: 100%;
    padding: 0px 15px;
  }
}

@media screen and (max-width: 400px) {
  .button-new-slider {
    width: 195px;
  }
}

/*---------------------------------- animaciones ------------------------------------- */

.animacion-inicio {
  opacity: 0;
}

.animacion-inicio-2 {
  opacity: 0;
}

.animacion-inicio-3 {
  opacity: 0;
}

.animacion-inicio-4 {
  opacity: 0;
}

.animacion-inicio-5 {
  opacity: 0;
}

.opacity-inicio {
  opacity: 1;
}

.animation-bottom-inicio {
  animation: moveInBottom 1.5s ease-out;
  animation-iteration-count: 1;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}