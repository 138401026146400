.body-admin {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.buttons-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.buttons-container button {
  margin: 15px;
}

.body-admin h1 {
  margin-bottom: 50px;
}

.container-1-admin {
  width: 500px;
  margin-bottom: 10px;
}

/*-------------------------- get.css ------------------------------------------------------------  */

.body-get {
  display: grid;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/*-------------------------- login.css ------------------------------------------------------------  */

.body-login {
  min-height: calc(100vh - 205px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-login {
  width: 500px;
  display: block;
}

.altura-body {
  min-height: calc(100vh - 205px);
}

@media screen and (max-width: 940px) {
  .altura-body {
    min-height: calc(100vh - 140px);
  }

  .body-login {
    min-height: calc(100vh - 140px);
  }
}

@media screen and (max-width: 550px) {
  .altura-body {
    min-height: calc(100vh - 190px);
  }

  .body-login {
    min-height: calc(100vh - 190px);
  }

  .container-1-admin {
    width: 320px;
  }

  .container-login {
    width: 90%;
  }
}
