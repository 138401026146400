.body-footer {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    background-color: #AE132E;
    position: relative;
}

.body-footer text {
    color: #FFFFFF;
    font-size: 14px;
    margin-left: 15px;
}

.container-footer-1 {
    position: absolute;
    display: flex;
    align-items: center;
    right: 15px;
}

.container-whatsapp-footer {
    width: 24px;
    height: 24px;
    background-color: #25D366;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 2px;
    cursor: pointer;
}

.container-instagram-footer {
    width: 24px;
    height: 24px;
    background-color: #333333;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 2px;
    cursor: pointer;
}

.container-link-footer {
    width: 24px;
    height: 24px;
    background-color: #818A91;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 2px;
    cursor: pointer;
}

@media screen and (max-width: 550px) {
    .body-footer {
        display: block;
        height: 100px;
    }

    .body-footer text {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 10%;
        width: 80%;
    }

    .container-footer-1 {
        left: 50%;
        transform: translateX(-25%);
        top: 70%;
    }

}