.body-link {
    width: 100%;
    display: block;
    padding-bottom: 30px;
}

.container-1-link {
    width: 100%;
    height: 485px;
    position: relative;
    margin-bottom: 150px;
}

.container-1-link-img-1 {
    width: 100%;
    height: 100%;
}

.container-1-link-img-2 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -103px;
    height: 214px;
    width: 214px;
    border-radius: 50%;
}

.flex-container-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-2-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    border: 2px solid #333333;
    border-radius: 20px;
    width: 40%;
    height: 155px;
    margin: 10px;
    cursor: pointer;
}

.container-2-link img {
    width: 109px;
    height: 109px;
    margin: 0px 25px;
}

.container-2-link p {
    color: #333333;
    font-size: 32px;
    font-weight: bold;
    margin: 0px 25px;
}

@media screen and (max-width: 1100px) {
    .container-2-link {
        width: 50%;
    }
}

@media screen and (max-width: 915px) {
    .flex-container-link {
        display: block;
    }

    .container-2-link {
        width: 95%;
    }
}

@media screen and (max-width: 550px) {
    .container-2-link {
        height: 100px;
        margin: 10px;
    }

    .container-2-link img {
        width: 70px;
        height: 70px;
        margin: 0px 10px;
    }

    .container-2-link p {
        color: #333333;
        font-size: 25px;
        margin: 0px 10px;
    }
}

@media screen and (max-width: 380px) {
    .container-2-link {
        height: 90px;
        margin: 10px;
    }

    .container-2-link img {
        width: 60px;
        height: 60px;
        margin: 0px 10px;
    }

    .container-2-link p {
        color: #333333;
        font-size: 22px;
        margin: 0px 10px;
    }
}