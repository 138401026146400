* {
  padding: 0;
  margin: 0;
  font-family: "Poppins";
}

.body-loader {
  min-height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  font-size: 50px;
  color: #000000;
}

@media screen and (max-width: 940px) {
  .body-loader {
    min-height: calc(100vh - 140px);
  }
}

@media screen and (max-width: 550px) {
  .body-loader {
    min-height: calc(100vh - 190px);
  }
}
